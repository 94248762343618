export const seo = {
  url: 'firma/dla-akcjonariuszy',
  title: {
    pl: 'Dla Akcjonariuszy',
    en: 'For Stakeholders',
  },
  desc: {
    pl: `W tej sekcji znajdują się informacje dotyczące komunikacji z akcjonariuszami spółki Omida Sea And Air S.A.`,
    en: `This section contains information on communication with the shareholders of Omida Sea And Air S.A.`,
  },
  keywords: ['omida', 'sea and air', 'sea & air', 'akcjonariusz'],
}

export const intro = {
  title: {
    pl: 'Dla Akcjonariuszy',
    en: 'For Stakeholders',
  },
  desc: {
    pl: `W tej sekcji znajdują się informacje dotyczące komunikacji z akcjonariuszami spółki Omida Sea And Air S.A.`,
    en: `This section contains information on communication with the shareholders of Omida Sea And Air S.A.`,
  },
}

export const main = {
  title: {
    pl: 'Wezwanie do złożenia akcji w spółce',
    en: 'Wezwanie do złożenia akcji w spółce',
  },
  html: {
    pl: `
    <p>
    W związku z wejściem w życie ustawy z dnia 30 sierpnia 2019 r. o zmianie ustawy Kodeks spółek handlowych oraz niektórych innych ustaw (Dz.U. z 2019 r., poz. 1798), dalej jako <strong>„Ustawa”</strong>, <strong>Omida Sea And Air Spółka Akcyjna</strong> (dalej jako: <strong>„Spółka”</strong>), informuje akcjonariuszy o wprowadzeniu do polskiego porządku prawnego <strong>obowiązkowej dematerializacji akcji spółek, która oznacza zastąpienie papierowej formy akcji zapisem elektronicznym w rejestrze akcjonariuszy</strong> prowadzonym przez podmiot, o którym mowa w art. 3281  § 2 Kodeksu spółek handlowych w brzmieniu określonym Ustawą.
    </p>
    <p>
    Zgodnie z wprowadzonymi zmianami moc obowiązujących dokumentów akcji wydanych przez Spółkę wygasa z mocy prawa z dniem 1 marca 2021 r. Po tym dniu akcje nie będą dokumentem potwierdzającym status akcjonariusza, lecz wyłącznie dokumentem dowodowym, niezbędnym do aktualizacji <strong>elektronicznego rejestru akcjonariuszy</strong> Natomiast po dniu 1 marca 2026 r. nastąpi utrata ochrony praw członkowskich przez akcjonariuszy, których dokumenty <strong>akcji nie zostały złożone w spółce i nie zostały ujęte w elektronicznym rejestrze akcjonariuszy.</strong>
    </p>
    <p>
    W związku z powyższymi zmianami, <strong>zarząd Spółki wzywa wszystkich akcjonariuszy</strong> do złożenia dokumentów akcji najpóźniej do dnia <strong>31.12.2020 r.</strong> w siedzibie Spółki: <strong>Pl. Kaszubski 8/301, 81-350 Gdynia</strong>, tak by mogły być przekształcone w formę zapisu elektronicznego. Złożenie dokumentów akcji w Spółce odbywa się za pisemnym potwierdzeniem wydanym akcjonariuszowi.
    </p>
    <p>
    Więcej informacji w sprawie dematerializacji akcji można uzyskać pod numerem telefonu: <a href="tel:+48731500445">+48 731 50 04 45</a>.
    </p>
    `,
    en: `
    <p>
    W związku z wejściem w życie ustawy z dnia 30 sierpnia 2019 r. o zmianie ustawy Kodeks spółek handlowych oraz niektórych innych ustaw (Dz.U. z 2019 r., poz. 1798), dalej jako <strong>„Ustawa”</strong>, <strong>Omida Sea And Air Spółka Akcyjna</strong> (dalej jako: <strong>„Spółka”</strong>), informuje akcjonariuszy o wprowadzeniu do polskiego porządku prawnego <strong>obowiązkowej dematerializacji akcji spółek, która oznacza zastąpienie papierowej formy akcji zapisem elektronicznym w rejestrze akcjonariuszy</strong> prowadzonym przez podmiot, o którym mowa w art. 3281  § 2 Kodeksu spółek handlowych w brzmieniu określonym Ustawą.
    </p>
    <p>
    Zgodnie z wprowadzonymi zmianami moc obowiązujących dokumentów akcji wydanych przez Spółkę wygasa z mocy prawa z dniem 1 marca 2021 r. Po tym dniu akcje nie będą dokumentem potwierdzającym status akcjonariusza, lecz wyłącznie dokumentem dowodowym, niezbędnym do aktualizacji <strong>elektronicznego rejestru akcjonariuszy</strong> Natomiast po dniu 1 marca 2026 r. nastąpi utrata ochrony praw członkowskich przez akcjonariuszy, których dokumenty <strong>akcji nie zostały złożone w spółce i nie zostały ujęte w elektronicznym rejestrze akcjonariuszy.</strong>
    </p>
    <p>
    W związku z powyższymi zmianami, <strong>zarząd Spółki wzywa wszystkich akcjonariuszy</strong> do złożenia dokumentów akcji najpóźniej do dnia <strong>31.12.2020 r.</strong> w siedzibie Spółki: <strong>Pl. Kaszubski 8/301, 81-350 Gdynia</strong>, tak by mogły być przekształcone w formę zapisu elektronicznego. Złożenie dokumentów akcji w Spółce odbywa się za pisemnym potwierdzeniem wydanym akcjonariuszowi.
    </p>
    <p>
    Więcej informacji w sprawie dematerializacji akcji można uzyskać pod numerem telefonu: <a href="tel:+48731500445">+48 731 50 04 45</a>.
    </p>
    `,
  },
}
